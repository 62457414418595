@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --primary: 86, 73%, 66%;
    --primary-100: 86, 73%, 85%;
    --primary-300: 86, 71%, 73%;
    --primary-600: 85, 85%, 35%;
    --primary-800: 86, 69%, 23%;

    --secondary: 240, 6%, 10%;
    --secondary-0: 0, 0%, 100%;
    --secondary-50: 0, 0%, 98%;
    --secondary-100: 240, 5%, 96%;
    --secondary-200: 240, 6%, 90%;
    --secondary-300: 240, 5%, 84%;
    --secondary-400: 240, 5%, 65%;
    --secondary-500: 240, 5%, 65%;
    --secondary-600: 240, 5%, 34%;
    --secondary-700: 240, 5%, 26%;
    --secondary-900: 225, 52%, 12%;

    --brand-grey-10: 0, 0%, 88%;
    --brand-white: 0, 0%, 100%;

    --danger: 0, 72%, 51%;
    --danger-100: 0, 93%, 94%;
    --danger-500: 0, 84%, 60%;

    --content-magenta: 320, 100%, 35%;
    --content-amber: 26, 90%, 37%;
    --content-blue: 226, 71%, 40%;
    --content-teal: 175, 77%, 26%;
    --content-yellow: 48, 100%, 82%;

    --radius: 0.5rem;
  }

  .dark {
    --primary: 86, 73%, 66%;
    --primary-100: 86, 73%, 85%;
    --primary-300: 86, 71%, 73%;
    --primary-600: 85, 85%, 35%;
    --primary-800: 86, 69%, 23%;

    --secondary: 240, 6%, 10%;
    --secondary-0: 0, 0%, 100%;
    --secondary-50: 0, 0%, 98%;
    --secondary-100: 240, 5%, 96%;
    --secondary-200: 240, 6%, 90%;
    --secondary-300: 240, 5%, 84%;
    --secondary-400: 240, 5%, 65%;
    --secondary-500: 240, 5%, 65%;
    --secondary-600: 240, 5%, 34%;
    --secondary-700: 240, 5%, 26%;
    --secondary-900: 240, 6%, 10%;

    --danger: 0, 72%, 51%;
    --danger-100: 0, 93%, 94%;
    --danger-500: 0, 84%, 60%;

    --content-magenta: 320, 100%, 35%;
    --content-amber: 26, 90%, 37%;
    --content-blue: 226, 71%, 40%;
    --content-teal: 175, 77%, 26%;
    --content-yellow: 48, 100%, 82%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-secondary-300 font-sans;
  }
  body {
    @apply bg-secondary-100 text-secondary;
  }
}
